import { useMemo } from 'react';
import { toHTML } from 'slack-markdown';

import { type Media } from '@lp-lib/game';

import config from '../../../config';
import { CloseIcon } from '../../icons/CloseIcon';
import { MediaPreview } from '../../MediaUploader/MediaPreview';

export function SlackMessagePreview(props: {
  text: string;
  media: Media | null;
  className?: string;
}): JSX.Element {
  const previewHTML = useMemo(() => {
    return toHTML(props.text, { escapeHTML: false });
  }, [props.text]);

  return (
    <div
      className={`w-full p-4 pr-7.5 border border-secondary rounded-xl ${props.className}`}
    >
      <div className='flex flex-row items-center gap-2'>
        <img
          src={config.slack.botIconURL}
          alt='luna park'
          className='w-6 h-6 border border-[#2E2E2E]'
        />
        <h3 className='font-bold text-sms'>{config.slack.botUsername}</h3>
      </div>

      <div className='mt-1 w-full pl-8 text-sms text-left'>
        <div
          className='message-template-preview'
          dangerouslySetInnerHTML={{
            __html: previewHTML,
          }}
        ></div>

        {props.media && (
          <div className='mt-5'>
            <MediaPreview media={props.media} />
          </div>
        )}
      </div>
    </div>
  );
}

export function SlackMessageInput(props: {
  text: string;
  onTextChange: (value: string) => void;
  media: Media | null;
  onMediaChange: (value: Media | null) => void;
  onMediaReset: () => void;
}): JSX.Element {
  const { text, onTextChange, media, onMediaChange, onMediaReset } = props;

  return (
    <div className='w-full'>
      <div className='relative w-full'>
        <textarea
          className='field h-48 p-5 mb-0 scrollbar'
          value={text}
          onChange={(e) => onTextChange(e.target.value)}
          maxLength={2000}
        ></textarea>

        <div className='absolute right-0 top-full'>
          {media ? (
            <div className='flex items-center bg-secondary rounded-xl'>
              <img
                className='w-18 h-10 rounded-l-xl'
                src={media.firstThumbnailUrl ?? ''}
                alt='lp'
              ></img>

              <div className='ml-3 text-sms'>Video</div>

              <button
                className='btn ml-2 mr-3'
                onClick={() => onMediaChange(null)}
              >
                <CloseIcon className='w-3 h-3 fill-current' />
              </button>
            </div>
          ) : (
            <button
              className='btn text-icon-gray underline text-sms'
              onClick={onMediaReset}
            >
              Re-Attach Video
            </button>
          )}
        </div>
      </div>

      <div className='mt-2 text-icon-gray text-3xs font-medium text-left'>
        {`*bold* _italics_ ~strike~ :emoji: <https://www.link.com|link>`}
      </div>
    </div>
  );
}
